export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Inbox game",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://html-classic.itch.zone/html/11717606/InBox Player/index.html",
    domain: "inboxonline.online",
    gaId: "G-F7F7EZBSYQ",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
